import React from "react"
import { graphql } from "gatsby"
import classNames from "classnames"
import upperFirst from "lodash/upperFirst"

import Layout from "../components/layout"
import PageBuilder from "../components/pageBuilder/pageBuilder"
import Seo from "../components/seo/seo"
import HeroSelector from "../components/hero/heroSelector"
import Sidebar from "../components/sidebar/sidebar"
import RichText from "../components/richText/richText"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"

import { useTranslation } from "../helpers/useTranslation"
import { findAvailableModule } from "../helpers/findAvailableModule"

import * as styles from "./resource.module.scss"

// It's imported this way because it's also used in gatsby-node
const generatePath = require("../helpers/generatePath")

const Resource = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { content, seo } = data.sanityResource.tabs
  const { _createdAt, _updatedAt } = data.sanityResource
  const categories = data.allSanityResourceCategory
  const { _rawPageBuilder, _rawFeaturedImage, _rawForm, _rawHero, _rawRichText, title } = content
  const hasSidebar = _rawFeaturedImage?.asset || _rawForm?.link
  const translations = data.sanityTranslationResource?.translation
  const enPathUrl = translations && generatePath(translations[0])
  const esPathUrl = translations && generatePath(translations[1])
  // const auPathUrl = translations && generatePath(translations[2])
  // const ptPathUrl = translations && generatePath(translations[3])
  const link = pageContext.market === "en-us" ? "resources" : `${pageContext.market}/resources`
  const availableFaq = findAvailableModule(content._rawPageBuilder, "faq", "faqNoImage")

  const steps = [
    {
      link,
      title: "Resources",
    },
    {
      link: `${link}/${content.category.slug.current}`,
      title: content.category.title,
    },
  ]

  const renderContent = () => {
    if (hasSidebar)
      return (
        <div className="grid">
          <div className="gridItem large-3">
            <Sidebar
              entity="events"
              formEvent={upperFirst(t("register"))}
              image={_rawFeaturedImage}
              categories={categories}
              form={_rawForm}
            />
          </div>
          <div className="gridItem large-9 large-offset-4">
            <main>
              <RichText blocks={_rawRichText} />
            </main>
          </div>
        </div>
      )
    return (
      <main className={classNames("section", styles.resourceBody)}>
        <RichText blocks={_rawRichText} />
      </main>
    )
  }

  return (
    <Layout
      translations={translations}
      enPathUrl={enPathUrl}
      esPathUrl={esPathUrl}
      // auPathUrl={auPathUrl}
      // ptPathUrl={ptPathUrl}
    >
      <Seo
        title={title}
        isBlogPost={false}
        image={_rawFeaturedImage?.asset?.url}
        path={generatePath(pageContext.page)}
        datePublished={_createdAt}
        dateModified={_updatedAt}
        breadcrumbs={steps}
        faq={availableFaq}
        {...seo}
      />
      <Breadcrumbs homePageLabel={t("resources")} />
      {_rawHero && <HeroSelector heroSelector={[_rawHero]} />}
      {renderContent()}
      {_rawPageBuilder && <PageBuilder pageBuilder={_rawPageBuilder} />}
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!, $_id: String!, $market: String!) {
    sanityResource(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      _id
      _createdAt(formatString: "MMMM Do, YYYY")
      _updatedAt(formatString: "MMMM Do, YYYY")
      tabs {
        content {
          title
          market
          _rawHero(resolveReferences: { maxDepth: 10 })
          _rawFeaturedImage(resolveReferences: { maxDepth: 10 })
          _rawForm(resolveReferences: { maxDepth: 10 })
          _rawRichText(resolveReferences: { maxDepth: 10 })
          _rawPageBuilder(resolveReferences: { maxDepth: 10 })
          category {
            title
            slug {
              current
            }
          }
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
    allSanityResourceCategory(filter: { market: { eq: $market } }) {
      nodes {
        id
        title
        slug {
          current
        }
      }
    }
    sanityTranslationResource(translation: { elemMatch: { tabs: { content: {} }, _id: { eq: $_id } } }) {
      translation {
        _id
        _type
        tabs {
          content {
            market
            slug {
              current
            }
            category {
              id
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`

export default Resource
